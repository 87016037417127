import React from "react";
import cn from "classnames";
import styles from "./AppModeSelect.module.scss";

interface IPropTypes {}

const AppModeSelect = React.memo(
  React.forwardRef<HTMLDivElement, IPropTypes>((_, ref) => {
    return (
      <div
        className={styles.AppModeSelect}
        ref={ref}
        data-role="app-mode-select"
      >
        <div
          className={cn(styles.AppModeSelect__Item, {
            [styles.AppModeSelect__Item_State_Selected]: true,
          })}
        >
          quiz
        </div>
        <div className={styles.AppModeSelect__Item}>test</div>
        <div className={styles.AppModeSelect__Item}>quest</div>
      </div>
    );
  })
);

AppModeSelect.displayName = "AppModeSelect";

export default AppModeSelect;
