import { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react';

export function useScrollOpacityControl(scrollPercent: number): {
  setHeader: Dispatch<SetStateAction<HTMLElement | null>>;
  setScroller: Dispatch<SetStateAction<HTMLElement | null>>;
} {
  const [header, setHeader] = useState<HTMLElement | null>(null);
  const [scroller, setScroller] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    function onScroll() {
      if (scroller && header) {
        setOpacityByScroll(header, scroller.scrollTop, scrollPercent);
      }
    }
    if (scroller && header) {
      scroller.addEventListener('scroll', onScroll);
      onScroll();
    }

    return () => {
      scroller?.removeEventListener('scroll', onScroll);
    };
  }, [scroller, header, scrollPercent]);

  return {
    setHeader,
    setScroller,
  };
}

function setOpacityByScroll(
  header: HTMLElement,
  scrollTop: number,
  scrollPercent: number
) {
  const percent = Math.min((scrollTop / window.innerHeight) * 100, 100);
  const opacity = Math.min(percent / scrollPercent, 1);
  header.style.opacity = opacity.toString();
}
