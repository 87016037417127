import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../../components/_base/Text/Text";
import styles from "./index.module.scss";

const LogoutButton: React.FC<{ onLogout: () => void }> = React.memo(
  ({ onLogout }) => {
    const { t } = useTranslation();

    return (
      <button className={styles.LogoutButton} onClick={onLogout}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path
            d="M7.33333 13.333H12C13.1046 13.333 14 12.4376 14 11.333V4.66634C14 3.56177 13.1046 2.66634 12 2.66634H7.33333M2 7.99967L9.33333 7.99967M9.33333 7.99967L7.33333 9.99967M9.33333 7.99967L7.33333 5.99967"
            stroke="#FFF730"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Text fontSize={16} color="#999" textAlign="left" fontWeight={450}>
          {t("exitProfile")}
        </Text>
      </button>
    );
  }
);

LogoutButton.displayName = "LogoutButton";

export default LogoutButton;
