import cn from "classnames";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { IAnswer, IQuestion } from "../../types";
import { Button } from "../_base/Button/Button";
import { Text } from "../_base/Text/Text";
import { FlexCenter } from "../_base/Flex/Flex";
import { Answer, AnswerState } from "./Answer";
import styles from "./Quiz.module.scss";

interface IPropTypes {
  question: IQuestion;
  index: number;
  isShowExplanation: boolean;
  modeColor: string;
  onAnswer: (qIndex: number, aIndex: number, answer: IAnswer) => void;
  onNextClick: () => void;
}

export interface IQuestionHandler {
  highlightCorrect: () => void;
  setFriendAnswer: (answerId: number, photoURL: string) => void;
  setMyAnswer: (answerIndex: number, photoURL: string) => void;
}

export const Question = React.memo(
  React.forwardRef<IQuestionHandler, IPropTypes>(
    (
      { question, index, isShowExplanation, modeColor, onAnswer, onNextClick },
      ref
    ) => {
      const { t } = useTranslation();

      const [answerState, setAnswerState] = useState<{
        [answerIndex: number]: AnswerState;
      }>({});
      const [friendAnswer, setFriendAnswer] = useState<{
        [answerIndex: number]: string /* photoURL*/;
      }>({});
      const [myAnswer, setMyAnswer] = useState<{
        [answerIndex: number]: string /* photoURL*/;
      }>({});

      const correctAnswerIndex = useMemo<number>(
        () => question.answers.findIndex(({ is_correct }) => is_correct),
        [question]
      );

      const _onAnswer = useCallback(
        (aIndex: number) => {
          if (isEmpty(answerState)) {
            const answer = question.answers[aIndex];
            setAnswerState({
              [aIndex]: answer.is_correct ? "correct" : "incorrect",
            });
            onAnswer(index, aIndex, answer);
          }
        },
        [onAnswer, question.answers, index, answerState]
      );

      useImperativeHandle(
        ref,
        () => ({
          highlightCorrect: () => {
            setAnswerState((prev) => {
              return {
                ...prev,
                [correctAnswerIndex]: "correctHighlighted",
              };
            });
          },
          setFriendAnswer: (answerId, photoURL) => {
            setFriendAnswer((prev) => {
              const answerIndex = question.answers.findIndex(
                ({ id }) => id === answerId
              );
              if (answerIndex !== -1 && !prev[answerIndex]) {
                return { [answerIndex]: photoURL };
              }
              return prev;
            });
          },
          setMyAnswer: (answerIndex, photoURL) => {
            setMyAnswer((prev) => {
              if (!prev[answerIndex]) {
                return { [answerIndex]: photoURL };
              }
              return prev;
            });
          },
        }),
        [correctAnswerIndex, question]
      );

      return (
        <div
          className={cn(styles.Question, {
            [styles.Question_Explanation]: isShowExplanation,
          })}
        >
          {isShowExplanation && (
            <Text
              fontSize={16}
              fontWeight={500}
              textTransform="uppercase"
              margin="30rem 0 0"
              color={modeColor}
            >
              {t("correctAnswer")}
            </Text>
          )}
          {!isShowExplanation && (
            <div className={styles.Question__Text} data-role="question-text">
              {question.question}
            </div>
          )}
          {isShowExplanation ? (
            <>
              <div className={styles.Question__ExplanationText}>
                <FlexCenter>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    textAlign="left"
                    color="#ccc"
                  >
                    {question.explanation}
                  </Text>
                </FlexCenter>
              </div>
              <Button
                type="outlined"
                label={t("nextQuestion")}
                bgColor={modeColor}
                fontColor={modeColor}
                onClick={onNextClick}
              />
              <div className={styles.Question__ExplanationGradient} />
            </>
          ) : (
            <div className={styles.Question__AnswersContainer}>
              {question.answers.map((answer, i) => (
                <Answer
                  key={i}
                  answer={answer}
                  index={i}
                  answerState={answerState[i]}
                  friendPhotoURL={friendAnswer[i]}
                  myPhotoURL={myAnswer[i]}
                  onAnswer={_onAnswer}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);

Question.displayName = "Question";
