import React from "react";
import cn from "classnames";
import styles from "./Avatar.module.scss";

(window as any).cn = cn;

interface IPropTypes {
  size: 24 | 32 | 40 | 50 | 60;
  photoURL: string | null | undefined;
  extraClasses?: string | string[];
}

export const Avatar: React.FC<IPropTypes> = React.memo(
  ({ size, photoURL, extraClasses }) => {
    return (
      <div
        className={cn(styles.Avatar, extraClasses, {
          [styles.Avatar_Size_24]: size === 24,
          [styles.Avatar_Size_32]: size === 32,
          [styles.Avatar_Size_40]: size === 40,
          [styles.Avatar_Size_50]: size === 50,
          [styles.Avatar_Size_60]: size === 60,
        })}
        data-role="avatar"
        style={{
          backgroundImage: `url(${
            photoURL ? photoURL : "//s7.vcdn.biz/static/204339561/no_avatar.png"
          })`,
        }}
      />
    );
  }
);

Avatar.displayName = "Avatar";
