import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DesktopStub } from "./components/DesktopStub/DesktopStub";
import { UserProvider } from "./providers/UserProvider";
import "./firebaseClient";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { routes } from "./routes";
import { initAnalytics } from "./utils/analytics";
import { QuizzesProvider } from "./providers/QuizzesProvider";
import "./i18n";

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <UserProvider>
    <QuizzesProvider>
      <RouterProvider router={router} />
      <DesktopStub />
    </QuizzesProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let prevWidth: number | undefined;

function onResize(event?: Event) {
  if (prevWidth !== window.innerWidth || event) {
    setFontSize();
  } else {
    setTimeout(onResize, 50);
  }

  prevWidth = window.innerWidth;

  function setFontSize() {
    const targetWidth = 360;
    let width: number;
    if (document.documentElement) {
      width = document.documentElement.offsetWidth;
    } else {
      width = window.innerWidth;
    }
    const coef = width / targetWidth;
    document.documentElement.style.fontSize = coef + "px";
  }
}
window.addEventListener("resize", onResize);
document.addEventListener("DOMContentLoaded", onResize);
onResize();
initAnalytics();
