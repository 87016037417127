import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQuizUserResult, QuizMode, QuizModeColors } from "../../types";
import { BackButton, Button } from "../_base/Button/Button";
import { FlexCenter, FlexSpacer } from "../_base/Flex/Flex";
import { Text } from "../_base/Text/Text";
import { Avatar } from "../_base/Avatar/Avatar";
import Image from "../_base/Image/Image";
import { QuizModeSelector } from "../QuizModeSelector/QuizModeSelector";
import { Skeleton } from "../Skeleton/Skeleton";
import { NavBar } from "../NavBar/NavBar";
import quizModeDemoImg from "../../assets/images/quiz_mode2.png";
import quizModeCompetitionImg from "../../assets/images/quiz_mode1.png";
import styles from "./BeforeQuizLanding.module.scss";

interface IPropTypes {
  type: "default" | "generation" | "duel";
  title: string;
  description: string;
  isButtonLoading?: boolean;
  defaultMode?: QuizMode;
  friend?: IQuizUserResult;
  bgUrl?: string;
  onStartQuiz: (mode: QuizMode) => void;
  onBack?: () => void;
}

export const BeforeQuizLanding: React.FC<IPropTypes> = React.memo(
  ({
    bgUrl,
    type,
    title,
    description,
    isButtonLoading,
    defaultMode = QuizMode.competition,
    friend,
    onStartQuiz,
    onBack,
  }) => {
    const { t } = useTranslation();

    const [mode, setMode] = useState<QuizMode>(defaultMode);

    const buttonText = useMemo<string | undefined>(() => {
      let result: string | undefined;
      switch (type) {
        case "default":
          result = t("startQuiz");
          break;
        case "generation":
          result = isButtonLoading ? undefined : t("startQuiz");
          break;
        case "duel":
          result = t("startDuel");
          break;
      }

      return result;
    }, [type, isButtonLoading, t]);

    const isShowSkeleton = type === "generation" && !title && !description;
    const isDuel = type === "duel" && Boolean(friend);

    return (
      <div
        className={styles.BeforeQuizLanding}
        style={{
          background: bgUrl
            ? `url(${bgUrl}) lightgray 50% / cover no-repeat`
            : "#000",
          "--mode-color": QuizModeColors[mode],
        }}
      >
        <div className={styles.BeforeQuizLanding__Gradient} />
        {onBack && (
          <div className={styles.BeforeQuizLanding__BackWrapper}>
            <BackButton onClick={onBack} />
          </div>
        )}
        <div className={styles.BeforeQuizLanding__Content}>
          {isDuel && <DuelModeLabel mode={mode} />}
          <FlexSpacer grow={2} />
          {isDuel && (
            <FriendCard name={friend!.userName} photoURL={friend!.photoURL} />
          )}
          {isShowSkeleton && (
            <>
              <Text
                classNames={styles.BeforeQuizLanding__GenerationText}
                fontSize={16}
                fontWeight={450}
                color="#ccc"
              >
                {t("quizGenerating")}
              </Text>
              <div className={styles.BeforeQuizLanding__SkeletonWrapper}>
                <Skeleton />
              </div>
            </>
          )}
          {title && description && (
            <>
              <Text
                classNames={styles.BeforeQuizLanding__Title}
                fontFamily="Yanone Kaffeesatz"
                fontSize={28}
                fontWeight={500}
                lineHeight="26rem"
                color="white"
              >
                {title}
              </Text>
              <Text
                classNames={styles.BeforeQuizLanding__Description}
                fontSize={14}
                fontWeight={400}
                color="#F5F5F5"
                textAlign="left"
              >
                {description}
              </Text>
            </>
          )}
          {type !== "duel" && (
            <QuizModeSelector mode={mode} onChange={setMode} />
          )}
          <FlexSpacer />
          <FlexCenter>
            <Button
              bgColor="#B6F36A"
              isLoading={isButtonLoading}
              isDisabled={isButtonLoading || mode === null}
              loaderType={"sized"}
              label={buttonText}
              onClick={() => {
                if (mode) {
                  onStartQuiz(mode);
                }
              }}
            />
          </FlexCenter>
        </div>
        <NavBar />
      </div>
    );
  }
);

BeforeQuizLanding.displayName = "BeforeQuizLanding";

const DuelModeLabel: React.FC<{ mode: QuizMode | null }> = React.memo(
  ({ mode }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.DuelModeLabel}>
        <div className={styles.DuelModeLabel__Image}>
          <Image
            src={
              mode === QuizMode.competition
                ? quizModeCompetitionImg
                : quizModeDemoImg
            }
            alt="mode"
          />
        </div>
        <Text fontSize={16} color="white">
          {mode === QuizMode.competition
            ? t("challengeMode")
            : t("educationalMode")}
        </Text>
      </div>
    );
  }
);

const FriendCard: React.FC<{ name: string; photoURL: string }> = React.memo(
  ({ name, photoURL }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.FriendCard}>
        <Avatar
          extraClasses={styles.FriendCard__Avatar}
          size={60}
          photoURL={photoURL}
        />
        <Text fontSize={16} color="white">
          {t("youInvited")}
        </Text>
        <Text classNames={styles.FriendCard__Name} fontSize={20}>
          {name}
        </Text>
      </div>
    );
  }
);
