import React, { useCallback, useContext } from "react";
import cn from "classnames";
import { WrappedLoader } from "../_base/Loader/Loader";
import { Text } from "../_base/Text/Text";
import { BackButton } from "../_base/Button/Button";
import { ProfileScores } from "../Header/Header";
import { NavBar } from "../NavBar/NavBar";
import { UserContext } from "../../providers/UserProvider";
import { useScrollOpacityControl } from "../Header/useScrollOpacityController";
import styles from "./ListLayout.module.scss";

interface IPropTypes {
  position: "fixed" | "static";
  children: React.ReactNode;
  title?: string;
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  isAddBottomSpace?: boolean;
  isShowNavBar?: boolean;
  isLoading?: boolean;
  zIndex?: number;
  onBack?: () => void;
}

export const ListLayout: React.FC<IPropTypes> = React.memo(
  ({
    position,
    children,
    title,
    scrollRef,
    isAddBottomSpace = true,
    isShowNavBar,
    isLoading,
    zIndex,
    onBack,
  }) => {
    const { ownUser: user } = useContext(UserContext);
    const { setHeader, setScroller } = useScrollOpacityControl(10);

    const onScrollRef = useCallback(
      (ref: HTMLDivElement | null) => {
        setScroller(ref);

        if (scrollRef) {
          scrollRef.current = ref;
        }
      },
      [setScroller, scrollRef]
    );

    return (
      <div
        className={cn(styles.ListLayout, {
          [styles.ListLayout_Pos_Fixed]: position === "fixed",
          [styles.ListLayout_Pos_Static]: position === "static",
        })}
        style={zIndex !== undefined ? { zIndex } : undefined}
      >
        <header className={styles.ListLayout__Header}>
          {onBack && <BackButton onClick={onBack} />}
          {user.data && <ProfileScores scores={user.data.totalScore} />}
          <div ref={setHeader} className={styles.ListLayout__HeaderGradient} />
        </header>
        <div className={styles.ListLayout__Content} ref={onScrollRef}>
          {title && (
            <Text
              fontSize={24}
              color="white"
              textAlign="left"
              classNames={styles.ListLayout__Title}
            >
              {title}
            </Text>
          )}
          {children}
          {isAddBottomSpace && (
            <div className={styles.ListLayout__BottomSpace} />
          )}
        </div>
        {isLoading && <WrappedLoader />}
        {isShowNavBar && <NavBar />}
      </div>
    );
  }
);

ListLayout.displayName = "ListLayout";
