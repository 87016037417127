import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

type Scene = {
    id: string;
    is_final: boolean;
    is_win: boolean;
    content: string;
    choices_scenes_mapping: Record<string, string | null>;
    images: string[];
};

type Movie = {
    id: number;
    title: string;
    start_scene_id: number;
};

const QuestScreen: React.FC = () => {
    const { movieId } = useParams<{ movieId: string }>();
    const location = useLocation();
    const isListView = location.pathname === '/quest/list';
    const [movies, setMovies] = useState<Movie[]>([]);
    const [scene, setScene] = useState<Scene | null>(null);
    const [score, setScore] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [apiVersion, setApiVersion] = useState<'v2' | 'v3'>('v3');
    const navigate = useNavigate();
    const [fadeOut, setFadeOut] = useState(false);

    // Создадим константу с базовым URL API
    const API_BASE_URL = `https://movie-quest.et-rc.mggs.megogo.net/api/${apiVersion}`;

    const loadMovies = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/movies`);
            const data = await response.json();
            setMovies(data.movies);
        } catch (error) {
            console.error('Failed to load movies:', error);
        }
    }, [API_BASE_URL]);

    const loadScene = useCallback(async (movieId: string, sceneId: string) => {
        try {
            const response = await fetch(
                `${API_BASE_URL}/movies/${movieId}/scenes/${sceneId}`
            );
            const data: Scene = await response.json();
            setScene(data);
        } catch (error) {
            console.error('Failed to load scene:', error);
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        loadMovies();
    }, [apiVersion, loadMovies]);

    useEffect(() => {
        if (movieId) {
            const movie = movies.find(m => m.id.toString() === movieId);
            if (movie) {
                loadScene(movieId, movie.start_scene_id.toString());
            }
        }
    }, [movieId, movies, loadScene]);

    const handleChoiceClick = async (choiceId: string) => {
        if (!scene || !movieId) return;
        
        setFadeOut(true);
        
        await new Promise(resolve => setTimeout(resolve, 300));
        
        const nextSceneId = scene.choices_scenes_mapping[choiceId];

        if (nextSceneId === null) {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `https://movie-quest.et-rc.mggs.megogo.net/api/v2/movies/${movieId}/scenes/${scene.id}/choices/${choiceId}`
                );
                const newScene: Scene = await response.json();
                setScene(newScene);
                setScore(score + 1);
            } catch (error) {
                console.error('Failed to generate new scene:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            loadScene(movieId, nextSceneId);
            setScore(score + 1);
        }

        setFadeOut(false);
        document.getElementById('content')?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMovieClick = (movie: Movie) => {
        navigate(`/quest/movie/${movie.id}`);
    };

    return (
        <div style={{ 
            padding: '20px', 
            backgroundColor: '#1e1e1e', 
            color: '#ffffff', 
            minHeight: '100vh'
        }}>
            {(isListView || !movieId) && (
                <div>
                    <div style={{
                        marginBottom: '20px',
                        padding: '15px',
                        backgroundColor: '#2c2c2c',
                        borderRadius: '8px'
                    }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                onClick={() => {
                                    setApiVersion('v2');
                                    setMovies([]);
                                    loadMovies();
                                }}
                                style={{
                                    padding: '10px 20px',
                                    backgroundColor: apiVersion === 'v2' ? '#4a90e2' : '#333',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    flex: 1
                                }}
                            >
                                API v2 (Классический)
                            </button>
                            <button
                                onClick={() => {
                                    setApiVersion('v3');
                                    setMovies([]);
                                    loadMovies();
                                }}
                                style={{
                                    padding: '10px 20px',
                                    backgroundColor: apiVersion === 'v3' ? '#4a90e2' : '#333',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    flex: 1
                                }}
                            >
                                API v3 (С целями)
                            </button>
                        </div>
                    </div>
                    <div>
                        {movies.map(movie => (
                            <button 
                                key={movie.id} 
                                onClick={() => handleMovieClick(movie)}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    padding: '10px',
                                    margin: '10px 0',
                                    backgroundColor: '#4a90e2',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    fontSize: '16px'
                                }}
                            >
                                {movie.title}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {(!isListView && movieId && scene) && (
                <div style={{
                    position: 'relative',
                    minHeight: '100vh'
                }}>
                    {scene.images && scene.images.length > 0 && (
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url(${scene.images[0]})`,
                            backgroundSize: 'cover',
                            opacity: fadeOut ? 0 : 0.75,
                            transition: 'opacity 0.3s ease-in-out',
                            zIndex: 1
                        }} />
                    )}
                    <div style={{
                        position: 'relative',
                        zIndex: 2,
                        opacity: fadeOut ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        <div id="content" style={{ 
                            maxHeight: '60vh', 
                            overflowY: 'scroll', 
                            padding: '20px', 
                            backgroundColor: 'rgba(44, 44, 44, 0.2)',
                            borderRadius: '8px',
                            marginBottom: '20px',
                            fontSize: '16px',
                            lineHeight: '1.6'
                        }}>
                            {scene.content.split('\n').map((paragraph, index) => (
                                <p key={index} style={{ marginBottom: '10px' }}>{paragraph}</p>
                            ))}
                        </div>
                        {!scene.is_final && (
                            <div style={{ 
                                marginTop: 'auto', 
                                position: 'fixed',
                                bottom: 20,
                                left: 20,
                                right: 20
                            }}>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    gap: '10px'
                                }}>
                                    {Object.keys(scene.choices_scenes_mapping).map((choiceId, index) => (
                                        <button
                                            key={choiceId}
                                            onClick={() => handleChoiceClick(choiceId)}
                                            disabled={isLoading}
                                            style={{ 
                                                padding: '15px', 
                                                margin: '0', 
                                                color: 'white', 
                                                border: 'none', 
                                                borderRadius: '5px', 
                                                cursor: isLoading ? 'not-allowed' : 'pointer',
                                                fontSize: '16px',
                                                opacity: isLoading ? 0.7 : 1,
                                                backgroundColor: [
                                                    'rgba(41, 128, 185, 0.9)',
                                                    'rgba(52, 152, 219, 0.9)',
                                                    'rgba(26, 188, 156, 0.9)'
                                                ][index % 3]
                                            }}
                                        >
                                            {isLoading ? 'Загрузка...' : choiceId}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {(!isListView && movieId && !scene) && (
                <div>Loading...</div>
            )}
            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    //backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        width: '50px',
                        height: '50px',
                        border: '5px solid #f3f3f3',
                        borderTop: '5px solid #3498db',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite'
                    }}></div>
                </div>
            )}
            <style>
                {`
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                `}
            </style>
        </div>
    );
};

export default QuestScreen;
