// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  UserCredential,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import {
  IOwnUser,
  IQuizListItem,
  IQuizResponse,
  IQuizTheme,
  IQuizUserResult,
  IUserTotalScores,
} from "./types";
import { Cache, CacheType } from "./utils/cache";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvpwXt0vDHVoo8Lc2nvBCBgUQU9-W7DMY",
  authDomain: "superquizquest.firebaseapp.com",
  projectId: "superquizquest",
  storageBucket: "superquizquest.appspot.com",
  messagingSenderId: "348548909579",
  appId: "1:348548909579:web:7b0ad4b93f18800682550e",
  measurementId: "G-DBPKEJLJVN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const storage = getStorage(app);
export const firebaseAuth = getAuth(app);

export function performAuth(): Promise<UserCredential | void> {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(firebaseAuth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // if (credential) {
      //   const token = credential.accessToken;
      //   // The signed-in user info.
      //   const user = result.user;
      //   // IdP data available using getAdditionalUserInfo(result)
      // }
      // ...
      return result;
    })
    .catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.customData.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export async function createQuiz(data: {
  userId: string;
  topic?: string;
  prompt?: string;
  language?: IOwnUser["lang"];
  difficulty?: IOwnUser["difficulty"];
}): Promise<{ quizId: string }> {
  const result = await httpsCallable<
    {
      userId: string;
      topic?: string;
      prompt?: string;
      language?: IOwnUser["lang"];
      difficulty?: IOwnUser["difficulty"];
    },
    { id: string }
  >(
    functions,
    "createQuizAPI"
  )(data);

  return { quizId: result.data.id };
}

export async function getQuizById(quizId: string): Promise<IQuizResponse> {
  const fileRef = ref(storage, `${quizId}.json`);
  const url = await getDownloadURL(fileRef);
  const response = await fetch(url);
  const quizResponse = (await response.json()) as IQuizResponse;

  quizResponse.quiz.forEach((question) => {
    question.answers.forEach((answer, i) => {
      answer.id = i;
    });

    shuffleArray(question.answers);
  });

  return quizResponse;
}

export async function getAllQuizzesList(
  lang?: string | null,
  difficulty?: IOwnUser["difficulty"] | null
): Promise<IQuizListItem[]> {
  const result = await httpsCallable<
    {
      lang?: string | null;
      difficulty?: IOwnUser["difficulty"] | null;
    },
    IQuizListItem[]
  >(
    functions,
    "getAllQuizzes"
  )({ lang, difficulty });

  return result.data;
}

function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export async function getUserInfo(data: {
  userId: string;
  userName: string | null;
  photoURL: string | null;
}): Promise<IOwnUser> {
  const { userId, userName, photoURL } = data;

  const result = await httpsCallable<
    {
      userId: string;
      userName: string;
      photoURL: string | null;
    },
    IOwnUser
  >(
    functions,
    "getUserInfo"
  )({ userId, photoURL, userName: userName || "N/A" });

  return result.data;
}

export async function setUserInfo(data: {
  userId: string;
  userName: string;
  photoURL: string | null;
  lang: string | null;
  difficulty: IOwnUser["difficulty"];
}): Promise<IOwnUser> {
  const { userId, userName, photoURL, lang, difficulty } = data;

  const result = await httpsCallable<
    {
      userId: string;
      userName: string;
      photoURL: string | null;
      lang: string | null;
      difficulty: IOwnUser["difficulty"];
    },
    IOwnUser
  >(
    functions,
    "setUserInfo"
  )({ userId, photoURL, userName: userName || "N/A", lang, difficulty });

  return result.data;
}

export async function saveQuizResult(data: {
  quizId: string;
  userId: string;
  userName: string | null;
  photoURL: string | null;
  score: number;
  answers: Array<number | null>;
  answerTimes: number[];
  answerPoints: number[];
  referrerUserId: string | null;
  type?: "speed";
}): Promise<void> {
  await httpsCallable<{
    quizId: string;
    userId: string;
    userName: string;
    photoURL: string | null;
    score: number;
    answers: Array<number | null>;
    answerTimes: number[];
    answerPoints: number[];
    referrerUserId: string | null;
    type?: "speed";
  }>(
    functions,
    "saveQuizResult"
  )({ ...data, userName: data.userName || "N/A" });
}

export async function getQuizResults(
  quizId: string
): Promise<IQuizUserResult[]> {
  const result = await httpsCallable<
    {
      quizId: string;
    },
    IQuizUserResult[]
  >(
    functions,
    "getQuizResults"
  )({ quizId });

  return result.data;
}

export async function getQuizResultByUserId(
  quizId: string,
  userId: string
): Promise<IQuizUserResult | undefined> {
  const result = await httpsCallable<
    {
      quizId: string;
      userId: string;
    },
    { quizResult: IQuizUserResult }
  >(
    functions,
    "getQuizResultByUserId"
  )({ quizId, userId });

  return result.data.quizResult;
}

export async function getLandingDataByPrompt(
  prompt: string,
  language?: IOwnUser["lang"],
  difficulty?: IOwnUser["difficulty"]
): Promise<{ title: string; description: string; imagePrompt: string }> {
  const result = await httpsCallable<
    {
      prompt: string;
      language?: IOwnUser["lang"];
      difficulty?: IOwnUser["difficulty"];
    },
    {
      topic: string;
      quiz_introduction: string;
      background_image_prompt: string;
    }
  >(
    functions,
    "getLandingDataByPrompt"
  )({ prompt, language, difficulty });

  return {
    title: result.data.topic,
    description: result.data.quiz_introduction,
    imagePrompt: result.data.background_image_prompt,
  };
}

export async function getImageByPrompt(prompt: string): Promise<string> {
  const result = await httpsCallable<
    {
      prompt: string;
    },
    string
  >(
    functions,
    "getImageByPrompt"
  )({ prompt });

  return result.data; // as base64 string, suppose that this is image/jpeg
}

export async function getThemesAndQuizzes(
  lang?: string | null,
  difficulty?: IOwnUser["difficulty"]
): Promise<IQuizTheme[]> {
  const result = await httpsCallable<
    { lang?: string | null; difficulty?: IOwnUser["difficulty"] },
    IQuizTheme[]
  >(
    functions,
    "getThemesAndQuizzes"
  )({ lang, difficulty });

  return result.data;
}

export async function getUsersScore(): Promise<IUserTotalScores[]> {
  let result: IUserTotalScores[];
  const cacheData = Cache.get<IUserTotalScores[]>(CacheType.usersScore);

  if (!cacheData) {
    const response = await httpsCallable<void, IUserTotalScores[]>(
      functions,
      "getUsersScore"
    )();
    response.data.sort((a, b) => b.totalScore - a.totalScore);
    Cache.set(CacheType.usersScore, response.data);
    result = response.data;
  } else {
    result = cacheData;
  }

  return result;
}

export async function getRecommendedQuizzesForUser(
  userId: string | undefined,
  lang?: string | null,
  difficulty?: IOwnUser["difficulty"]
): Promise<IQuizListItem[]> {
  const result = await httpsCallable<
    {
      userId?: string;
      lang?: string | null;
      difficulty?: IOwnUser["difficulty"];
    },
    IQuizListItem[]
  >(
    functions,
    "getRecommendedQuizzesForUser"
  )({ userId, lang, difficulty });

  return result.data;
}
