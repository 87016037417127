import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";
import styles from "./Transition.module.scss";

type AnimationType = "fade" | "slideRight" | "slideDown" | "slideUp";

interface IPropTypes {
  isShow: boolean;
  animation?: AnimationType;
  children?: React.ReactNode;
  timeout?: number;
  onExited?: () => void;
}

const cnMap: Record<AnimationType, CSSTransitionClassNames> = {
  fade: {
    enter: styles.TransitionFade_State_Enter,
    enterActive: styles.TransitionFade_State_EnterActive,
    exit: styles.TransitionFade_State_Exit,
    exitActive: styles.TransitionFade_State_ExitActive,
  },
  slideRight: {
    enter: styles.TransitionSlideRight_State_Enter,
    enterActive: styles.TransitionSlideRight_State_EnterActive,
    exit: styles.TransitionSlideRight_State_Exit,
    exitActive: styles.TransitionSlideRight_State_ExitActive,
  },
  slideDown: {
    enter: styles.TransitionSlideDown_State_Enter,
    enterActive: styles.TransitionSlideDown_State_EnterActive,
    exit: styles.TransitionSlideDown_State_Exit,
    exitActive: styles.TransitionSlideDown_State_ExitActive,
  },
  slideUp: {
    enter: styles.TransitionSlideUp_State_Enter,
    enterActive: styles.TransitionSlideUp_State_EnterActive,
    exit: styles.TransitionSlideUp_State_Exit,
    exitActive: styles.TransitionSlideUp_State_ExitActive,
  },
};

const Transition: React.FC<IPropTypes> = ({
  isShow,
  animation = "fade",
  children,
  timeout = 400,
  onExited,
}) => {
  // to "fix" warning
  // https://github.com/reactjs/react-transition-group/issues/668
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      nodeRef={ref}
      in={isShow}
      classNames={cnMap[animation]}
      unmountOnExit
      timeout={timeout}
      onExited={onExited}
    >
      <div ref={ref}>{children}</div>
    </CSSTransition>
  );
};

Transition.displayName = "Transition";

export default React.memo(Transition);
