import React from "react";
import { BeforeQuizLanding } from "../../components/BeforeQuizLanding/BeforeQuizLanding";
import { IQuizResponse, IQuizUserResult, QuizMode } from "../../types";

interface IPropTypes {
  quiz: IQuizResponse;
  mode: QuizMode;
  friend: IQuizUserResult;
  onStartQuiz: (mode: QuizMode) => void;
}

export const QuizFriendShare: React.FC<IPropTypes> = React.memo(
  ({ quiz, mode, friend, onStartQuiz }) => {
    return (
      <BeforeQuizLanding
        type="duel"
        bgUrl={quiz.backgroundUrl}
        title={quiz.topic}
        description={quiz.quiz_introduction}
        defaultMode={mode}
        friend={friend}
        onStartQuiz={onStartQuiz}
      />
    );
  }
);
QuizFriendShare.displayName = "QuizFriendShare";
