import confetti, { Shape } from "canvas-confetti";

const TIME_TO_START_WINNER_ANIMATION = 500;
const CONFETTI_COLORS = ["#DFC00C", "#D51F67", "#00A4FF", "#FF91B6", "#79B12D"];
const confettiStyles = {
  particleCount: CONFETTI_COLORS.length,
  shapes: ["circle", "square", "circle", "square"] as Shape[],
  colors: CONFETTI_COLORS,
  decay: 0.93,
  scalar: 1.5,
  zIndex: 30,
};

export function startConfettiAnimation() {
  const canvas = document.createElement("canvas");
  const end = Date.now() + 3 * 1000;

  canvas.id = "confetti";
  document.body.appendChild(canvas);

  const winnerConfetti = confetti.create(canvas, {
    resize: true,
    useWorker: true,
  });

  setTimeout(frame, TIME_TO_START_WINNER_ANIMATION);

  function frame() {
    winnerConfetti({
      ...confettiStyles,
      origin: { x: 0, y: 1 },
      angle: 60,
      spread: 90,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }
}
