import cn from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';
import styles from './Quiz.module.scss';
import { ITimerState } from './useTimer';
import { QuizMode, QuizModeColors } from '../../types';

interface IPropTypes {
  timerState: ITimerState;
  duration: number;
  questionIndex: number;
  questionsLength: number;
  mode: QuizMode;
}

const SIZE = 58;
const STROKE_WIDTH = 6;
const RADIUS = 26;
const LOADER_LENGTH = 164; // 164 = 2*PI*R -- length of a circle

export const Timer: React.FC<IPropTypes> = React.memo(
  ({ timerState, duration, questionIndex, questionsLength, mode }) => {
    const loaderRef = useRef<SVGCircleElement | null>(null);

    useLayoutEffect(() => {
      if (loaderRef.current) {
        switch (mode) {
          case QuizMode.competition:
            loaderRef.current.style.animationDuration = `${duration}s`;
            break;
          case QuizMode.knowledge:
            loaderRef.current.style.strokeDashoffset = (
              (questionIndex / questionsLength) *
              LOADER_LENGTH
            ).toString();
            break;
        }
      }
    }, [duration, mode, questionIndex, questionsLength]);

    return (
      <div className={styles.Timer}>
        <svg viewBox={`0 0 ${SIZE} ${SIZE}`}>
          <circle
            cx={RADIUS + STROKE_WIDTH / 2}
            cy={RADIUS + STROKE_WIDTH / 2}
            r={RADIUS}
            strokeWidth={STROKE_WIDTH}
            stroke='rgba(255, 255, 255, 0.2)'
            fill='none'
          />
          <circle
            className={cn(styles.Timer__Loader, {
              [styles.Timer__Loader_Paused]: !timerState.isRunning,
              [styles.Timer__Loader_Counter]: mode === QuizMode.knowledge,
            })}
            ref={loaderRef}
            cx={RADIUS + STROKE_WIDTH / 2}
            cy={RADIUS + STROKE_WIDTH / 2}
            r={RADIUS}
            strokeWidth={STROKE_WIDTH}
            stroke={QuizModeColors[mode]}
            fill='none'
          />
        </svg>
        <div className={styles.Timer__Counter}>{`${
          questionIndex + 1
        }/${questionsLength}`}</div>
      </div>
    );
  }
);
Timer.displayName = 'Timer';
