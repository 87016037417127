import React, { useMemo } from "react";
import ReactSelect, {
  Props as ReactSelectProps,
  StylesConfig,
} from "react-select";
import styles from "./Select.module.scss";

type Option = { label: string; value: string };

const customStyle: StylesConfig = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "rgba(0, 0, 0, 0.15)" : "transparent",
    };
  },
};

interface IPropTypes {
  options: Array<Option>;
  onSelect: (option: Option | null) => void;
  value?: Option;
  placeholder?: string;
  isSearchable?: boolean;
  control?: React.ReactNode;
  isOpened?: boolean;
}

const Select: React.FC<IPropTypes> = React.memo(
  ({
    options,
    onSelect,
    placeholder,
    isSearchable = false,
    value,
    control,
    isOpened,
  }) => {
    const customComponents = useMemo(() => {
      const map: ReactSelectProps["components"] = {};

      if (control) {
        map.Control = () => control;
      }

      return map;
    }, [control]);

    return (
      <ReactSelect
        styles={customStyle}
        className={styles.Select}
        components={customComponents}
        options={options}
        isSearchable={isSearchable}
        placeholder={placeholder}
        value={value}
        menuPlacement="auto"
        menuIsOpen={isOpened}
        onChange={(newValue) => onSelect(newValue as Option)}
      />
    );
  }
);

Select.displayName = "Select";

export default Select;
