import { useCallback, useEffect, useRef, useState } from 'react';

export interface ITimerState {
  timeLeft: number;
  passedTime: number;
  isRunning: boolean;
}

export function useTimer(initTimeout: number | null, onTimer: () => void) {
  const [timerState, setTimerState] = useState<ITimerState>({
    timeLeft: initTimeout || 0,
    passedTime: 0,
    isRunning: false,
  });
  const timerRef = useRef<number | undefined>();
  const onTimerRef = useRef(onTimer);

  useEffect(() => {
    onTimerRef.current = onTimer;
  }, [onTimer]);

  const startTimer = useCallback(() => {
    const startTime = performance.now();
    setTimerState({
      isRunning: true,
      timeLeft: initTimeout || 0,
      passedTime: 0,
    });
    window.clearInterval(timerRef.current);
    timerRef.current = window.setInterval(() => {
      const passedTime = (performance.now() - startTime) / 1000;
      if (initTimeout !== null && passedTime > initTimeout) {
        onTimerRef.current();
        setTimerState({
          passedTime: initTimeout,
          timeLeft: 0,
          isRunning: false,
        });
        window.clearInterval(timerRef.current);
      } else {
        setTimerState((prev) => ({
          ...prev,
          passedTime,
          timeLeft:
            initTimeout !== null ? Math.ceil(initTimeout - passedTime) : 0,
        }));
      }
    }, 500);
  }, [initTimeout]);

  const stopTimer = useCallback(() => {
    window.clearInterval(timerRef.current);
    setTimerState((prev) => ({
      ...prev,
      isRunning: false,
    }));
  }, []);

  return {
    timerState,
    startTimer,
    stopTimer,
  };
}
