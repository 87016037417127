import { IQuizUserResult } from '../types';

interface ICache<T> {
  data: T | null;
  time: number;
}

export enum CacheType {
  usersScore = 'usersScore',
}

const cache = {
  [CacheType.usersScore]: {
    data: null,
    time: 0,
  } as ICache<IQuizUserResult[]>,
};

export const Cache = {
  get: <T = any>(type: CacheType, timeoutSecs = 60) => {
    const item = cache[type];
    const now = Date.now();
    let result: T | null = null;
    if ((now - item.time) / 1000 < timeoutSecs) {
      result = item.data as T;
    }
    return result;
  },
  set: (type: CacheType, data: any) => {
    const now = Date.now();
    cache[type] = {
      data,
      time: now,
    };
  },
};
