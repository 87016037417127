import React, { useMemo } from "react";
import { Link, generatePath } from "react-router-dom";
import cn from "classnames";
import { IQuizListItem } from "../../types";
import { ShareButton } from "../_base/Button/Button";
import Image from "../_base/Image/Image";
import { PATH } from "../../routes";
import styles from "./QuizzesList.module.scss";

interface IPropTypes {
  items: IQuizListItem[];
  isShowSharingButton: boolean;
  userId?: string;
  title?: string;
  description?: string;
  margin?: string;
  isFetching?: boolean;
}

export const QuizzesList: React.FC<IPropTypes> = React.memo(
  ({
    items,
    isShowSharingButton,
    userId,
    title,
    description,
    margin,
    isFetching,
  }) => {
    return (
      <div className={styles.QuizzesList} style={{ margin }}>
        {title && (
          <div className={styles.QuizzesList__TextWrapper}>
            <div className={styles.QuizzesList__Title}>{title}</div>
            {description && (
              <div className={styles.QuizzesList__Description}>
                {description}
              </div>
            )}
          </div>
        )}
        {isFetching && (
          <>
            <div
              className={cn(styles.QuizListItem, styles.QuizListItem_View_Stub)}
            />
            <div
              className={cn(styles.QuizListItem, styles.QuizListItem_View_Stub)}
            />
            <div
              className={cn(styles.QuizListItem, styles.QuizListItem_View_Stub)}
            />
          </>
        )}
        {items.map((item, i) => (
          <QuizListItem
            key={item.id + i}
            item={item}
            isShowSharingButton={isShowSharingButton}
            userId={userId}
          />
        ))}
      </div>
    );
  }
);

QuizzesList.displayName = "QuizzesList";

const QuizListItem: React.FC<{
  item: IQuizListItem;
  isShowSharingButton: boolean;
  userId: string | undefined;
}> = React.memo(({ item, isShowSharingButton, userId = "" }) => {
  const path = generatePath(PATH.quiz, { quizId: item.id });
  const url = useMemo<string>(() => {
    let result = "";
    if (isShowSharingButton) {
      const u = new URL(window.location.origin);
      u.pathname = path;
      u.searchParams.set("u", userId);
      result = u.toString();
    }

    return result;
  }, [path, userId, isShowSharingButton]);

  return (
    <Link to={path} className={styles.QuizListItem__Anchor}>
      <div className={styles.QuizListItem}>
        <Image
          className={styles.QuizListItem__Image}
          src={item.listBackgroundUrl}
          alt={item.topic}
          lazy={true}
        />
        <div className={styles.QuizListItem__Gradient} />
        {isShowSharingButton && (
          <div className={styles.QuizListItem__ShareContainer}>
            <ShareButton url={url} />
          </div>
        )}
        <div className={styles.QuizListItem__Title}>{item.topic}</div>
        {item.passCounter !== undefined && (
          <div className={styles.QuizListItem__UsersCounter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M12.75 12.1704C14.4978 12.5822 15.75 13.6935 15.75 14.9997"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.25 15C2.25 13.3431 4.26472 12 6.75 12C9.23528 12 11.25 13.3431 11.25 15"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.25 9.75C12.9069 9.75 14.25 8.40685 14.25 6.75C14.25 5.09315 12.9069 3.75 11.25 3.75"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.75 9.75C8.40685 9.75 9.75 8.40685 9.75 6.75C9.75 5.09315 8.40685 3.75 6.75 3.75C5.09315 3.75 3.75 5.09315 3.75 6.75C3.75 8.40685 5.09315 9.75 6.75 9.75Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {item.passCounter}
          </div>
        )}
      </div>
    </Link>
  );
});
