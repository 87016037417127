function callVibrate(pattern: number[] | number): void {
  if (typeof window.navigator.vibrate === 'function') {
    window.navigator.vibrate(pattern);
  }
}

export const vibrate = {
  correct: () => {
    callVibrate(100);
  },
  incorrect: () => {
    callVibrate([300, 100, 300]);
  }
}