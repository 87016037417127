import React from "react";
import styles from "./Text.module.scss";
import cn from "classnames";

interface IPropTypes {
  children: React.ReactNode;
  fontSize: number | string;
  fontFamily?: "Roboto" | "Yanone Kaffeesatz";
  color?: string;
  fontWeight?: 400 | 450 | 500 | 600 | 700;
  textAlign?: "center" | "right" | "left";
  textTransform?: "uppercase";
  lineHeight?: string | number;
  classNames?: string;
  letterSpacing?: string;
  margin?: string;
}

export const Text: React.FC<IPropTypes> = React.memo(
  ({
    children,
    fontSize,
    color,
    fontWeight,
    textAlign,
    textTransform,
    lineHeight,
    classNames,
    fontFamily = "Roboto",
    letterSpacing,
    margin,
  }) => {
    return (
      <div
        className={cn(styles.Text, classNames)}
        style={{
          color,
          fontWeight,
          textAlign,
          textTransform,
          fontSize: typeof fontSize === "string" ? fontSize : `${fontSize}rem`,
          lineHeight,
          fontFamily,
          letterSpacing,
          margin,
        }}
        data-role="text"
      >
        {children}
      </div>
    );
  }
);
Text.displayName = "Text";
