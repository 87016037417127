import cn from "classnames";
import React, { useCallback, useRef } from "react";
import styles from "./Input.module.scss";

interface IPropTypes {
  value: string;
  isBlocked?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const Input: React.FC<IPropTypes> = React.memo(
  ({ value, isBlocked, placeholder, onChange }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const _onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
      },
      [onChange]
    );

    return (
      <input
        ref={inputRef}
        className={cn(styles.Input, { [styles.Input_IsBlocked]: isBlocked })}
        value={value}
        placeholder={placeholder}
        onChange={_onChange}
      />
    );
  }
);

Input.displayName = "Input";
