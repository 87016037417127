import cn from "classnames";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PATH } from "../../routes";
import { QuizLeaderBoardScreen } from "../../screens/LeaderBoardScreen/LeaderBoardScreen";
import { IOnCompleteData } from "../../screens/QuizScreen/QuizScreen";
import {
  IQuizResponse,
  IQuizUserResult,
  QuizMode,
  QuizModeColors,
} from "../../types";
import { SessionStorageKeys, Storage } from "../../utils/storage";
import { CloseButton, ShareButton } from "../_base/Button/Button";
import { GobletIcon, PieIcon, RightArrowIcon } from "../_base/Icons/Icons";
import { Text } from "../_base/Text/Text";
import { UserContext } from "../../providers/UserProvider";
import { RecommendedQuizzesContext } from "../../providers/QuizzesProvider";
import { DuelInfo, SingleInfo } from "./CompletedInfo";
import { QuizzesList } from "../QuizzesList/QuizzesList";
import { useScrollOpacityControl } from "../Header/useScrollOpacityController";
import { NavBar } from "../NavBar/NavBar";
import { CategoriesList } from "../CategoriesList/CategoriesList";
import styles from "./QuizCompleted.module.scss";

interface IPropTypes {
  quizResp: IQuizResponse;
  completeData: IOnCompleteData;
  myPhotoUrl: string;
  friend: IQuizUserResult | null | undefined;
  leaderboardInfo: IQuizUserResult[];
  mode: QuizMode;
}

export const QuizCompleted: React.FC<IPropTypes> = React.memo(
  ({ quizResp, completeData, myPhotoUrl, friend, leaderboardInfo, mode }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { fbUser } = useContext(UserContext);
    const { list: allQuizzes } = useContext(RecommendedQuizzesContext);

    const { setHeader, setScroller } = useScrollOpacityControl(10);

    const [isShowLeaderBoard, setIsShowLeaderBoard] = useState(false);

    const myPosition = useMemo<number>(
      () =>
        leaderboardInfo.findIndex(({ userId }) => userId === fbUser?.uid) + 1,
      [leaderboardInfo, fbUser]
    );

    const onCloseClick = useCallback(() => {
      Storage.removeItem(window.sessionStorage, SessionStorageKeys.MAIN_SCROLL);

      navigate(PATH.root);
    }, [navigate]);

    return (
      <div className={styles.QuizCompleted}>
        <div ref={setHeader} className={styles.QuizCompleted__Header}>
          <CloseButton onClick={onCloseClick} />
        </div>
        <div ref={setScroller} className={styles.QuizCompleted__Content}>
          {friend ? (
            <DuelInfo
              quiz={quizResp.quiz}
              completeData={completeData}
              friend={friend}
              myPhotoUrl={myPhotoUrl}
            />
          ) : (
            <SingleInfo quizScores={completeData.scores} />
          )}
          <div className={styles.QuizCompleted__BubbleButtonsWrapper}>
            {!friend && (
              <BubbleButton
                type="outlined"
                leftIcon={<PieIcon />}
                separator="/"
                value1={completeData.correctCount}
                value2={quizResp.quiz.length}
                description={t("correctAnswer")}
              />
            )}
            <BubbleButton
              type="filled"
              leftIcon={<GobletIcon />}
              separator={t("of")}
              value1={myPosition}
              value2={leaderboardInfo.length}
              description={t("yourPosition")}
              onClick={() => {
                setIsShowLeaderBoard(true);
              }}
            />
          </div>
          <Share userId={fbUser?.uid} mode={mode} />
          {allQuizzes.length > 0 && (
            <QuizzesList
              title={t("youMayLike")}
              items={allQuizzes}
              isShowSharingButton={false}
            />
          )}
          <CategoriesList />
          {isShowLeaderBoard && (
            <QuizLeaderBoardScreen
              friendId={friend?.userId}
              quizResults={leaderboardInfo}
              quizTitle={quizResp.topic}
              onClose={() => {
                setIsShowLeaderBoard(false);
              }}
            />
          )}
        </div>
        <NavBar />
      </div>
    );
  }
);

QuizCompleted.displayName = "QuizCompleted";

const Share: React.FC<{ userId: string | undefined; mode: QuizMode }> =
  React.memo(({ userId = "", mode }) => {
    const url = useMemo<string>(() => {
      const u = new URL(window.location.href);
      u.search = "";
      u.searchParams.set("u", userId);
      u.searchParams.set("m", mode);
      return u.toString();
    }, [userId, mode]);

    return (
      <div className={styles.Share}>
        <div>
          <Trans i18nKey="shareQuiz">
            Поделись этим{" "}
            <span className={styles.Share__Highlighted}>Quiz</span> и сравни,
            сколько <span className={styles.Share__Highlighted}>coins</span>{" "}
            зарабатывают другие 👀
          </Trans>
        </div>
        <ShareButton url={url} color={QuizModeColors[mode]} />
      </div>
    );
  });

const BubbleButton: React.FC<{
  type: "outlined" | "filled";
  value1: number;
  value2: number;
  separator: string;
  leftIcon: React.ReactNode;
  description: string;
  onClick?: () => void;
}> = React.memo(
  ({ type, value1, value2, separator, leftIcon, description, onClick }) => {
    return (
      <div
        className={cn(styles.BubbleButton, {
          [styles.BubbleButton_Clickable]: Boolean(onClick),
          [styles.BubbleButton_Type_Filled]: type === "filled",
          [styles.BubbleButton_Type_Outlined]: type === "outlined",
        })}
        onClick={onClick}
      >
        {onClick && <RightArrowIcon />}
        {leftIcon}
        <div className={styles.BubbleButton__Counter}>
          <Text fontSize={32} lineHeight="32rem" fontWeight={600}>
            {value1}
          </Text>
          <Text fontSize={16} lineHeight="23rem">
            {separator}
          </Text>
          <Text fontSize={32} lineHeight="32rem" fontWeight={600}>
            {value2}
          </Text>
        </div>
        <Text fontSize={16}>{description}</Text>
      </div>
    );
  }
);
