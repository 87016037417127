import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListLayout } from "../ListLayout/ListLayout";
import { QuizzesList } from "../QuizzesList/QuizzesList";
import { IQuizListItem } from "../../types";
import { getAllQuizzesList } from "../../firebaseClient";
import { UserContext } from "../../providers/UserProvider";

interface IPropTypes {
  onCloseList: () => void;
}

export const AllQuizzesList: React.FC<IPropTypes> = React.memo(
  ({ onCloseList }) => {
    const { t } = useTranslation();

    const { ownUser: user } = useContext(UserContext);

    const [allQuizzes, setAllQuizzes] = useState<IQuizListItem[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      getAllQuizzesList(user.data?.lang, user.data?.difficulty)
        .then(setAllQuizzes)
        .finally(() => {
          setIsLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ListLayout
        position="fixed"
        title={t("allQuizzes")}
        zIndex={2}
        onBack={onCloseList}
      >
        <QuizzesList
          items={allQuizzes}
          isShowSharingButton={false}
          isFetching={isLoading}
        />
      </ListLayout>
    );
  }
);

AllQuizzesList.displayName = "AllQuizzesList";
