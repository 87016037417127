import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";
import { PATH } from "../../routes";
import { firebaseAuth } from "../../firebaseClient";
import { ProfileScores } from "../../components/Header/Header";
import { UserContext } from "../../providers/UserProvider";
import { BackButton, Button } from "../../components/_base/Button/Button";
import { WrappedLoader } from "../../components/_base/Loader/Loader";
import { Text } from "../../components/_base/Text/Text";
import { QuizzesList } from "../../components/QuizzesList/QuizzesList";
import { NavBar } from "../../components/NavBar/NavBar";
import AppModeSelect from "../../components/AppModeSelect/AppModeSelect";
import { useScrollOpacityControl } from "../../components/Header/useScrollOpacityController";
import LogoutButton from "./parts/LogoutButton";
import ProfileSettings from "./ProfileSettingsScreen";
import styles from "./ProfileScreen.module.scss";
import "react-spring-bottom-sheet/dist/style.css";

export const ProfileScreen: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const {
    fbUser,
    ownUser: { data: user },
  } = useContext(UserContext);

  const rootRef = useRef<HTMLDivElement | null>(null);

  const [isLogoutOpen, setIsLogoutOpen] = useState(false);

  const { setHeader, setScroller } = useScrollOpacityControl(10);

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onLogout = useCallback(() => {
    setIsLogoutOpen(true);
  }, []);

  const onConfirmLogout = useCallback(() => {
    firebaseAuth.signOut();
    setIsLogoutOpen(false);
  }, []);

  const onCloseBottomSheet = useCallback(() => {
    setIsLogoutOpen(false);
  }, []);

  const onRootRef = useCallback(
    (ref: HTMLDivElement | null) => {
      rootRef.current = ref;
      setScroller(ref);
    },
    [setScroller]
  );

  useEffect(() => {
    if (!fbUser) {
      navigate(PATH.root, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser]);

  if (!user) {
    return (
      <div className={styles.ProfileScreen}>
        <WrappedLoader />
      </div>
    );
  }

  return (
    <div className={styles.ProfileScreen} ref={onRootRef}>
      <NavBar />
      <div className={styles.ProfileScreen__Header}>
        <div ref={setHeader} className={styles.ProfileScreen__HeaderGradient} />
        <BackButton onClick={onBackClick} />
        <ProfileScores scores={user.totalScore} />
      </div>
      <ProfileSettings />

      {user.createdQuizzes.length > 0 && (
        <div className={styles.ProfileScreen__AppModeSelect}>
          <AppModeSelect />
        </div>
      )}

      {user.createdQuizzes.length > 0 && (
        <QuizzesList
          items={user.createdQuizzes}
          userId={user.userId}
          isShowSharingButton={true}
          title={t("quizzesYouCreated")}
          description={t("shareQuizzes")}
          margin="20rem 0 0"
        />
      )}

      {user.completedQuizzes.length > 0 ? (
        <QuizzesList
          items={user.completedQuizzes}
          userId={user.userId}
          isShowSharingButton={true}
          title={t("quizzesYouPassed")}
          margin="20rem 0 0"
        />
      ) : (
        <>
          <Text
            fontFamily="Yanone Kaffeesatz"
            fontSize={24}
            fontWeight={700}
            color="#fff"
            textAlign="left"
            margin="20rem 0 30rem 0"
          >
            {t("noCompletedQuizzes")}
          </Text>
          <Button extraClass={styles.ProfileScreen__TakeQuiz}>
            {t("takeQuiz")}
          </Button>
        </>
      )}

      <LogoutButton onLogout={onLogout} />

      <BottomSheet
        className={styles.ProfileScreen__BottomSheet}
        open={isLogoutOpen}
        onDismiss={onCloseBottomSheet}
      >
        <div className={styles.ProfileScreen__BottomSheet__Wrapper}>
          <Text fontSize={20} color="#121212">
            {t("exitProfileTitle")}
          </Text>
          <div className={styles.ProfileScreen__BottomSheet__ButtonWrapper}>
            <Button
              type="filled"
              bgColor="#121212"
              fontColor="white"
              label={t("yes")}
              onClick={onConfirmLogout}
            />
            <Button
              type="outlined"
              bgColor="#121212"
              label={t("no")}
              onClick={onCloseBottomSheet}
            />
          </div>
        </div>
      </BottomSheet>
    </div>
  );
});

ProfileScreen.displayName = "ProfileScreen";
