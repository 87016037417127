import { User } from "firebase/auth";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { firebaseAuth, getUserInfo } from "../firebaseClient";
import { IOwnUser } from "../types";
import { WrappedLoader } from "../components/_base/Loader/Loader";
import { setLanguage } from "../i18n";

export const UserProvider: React.FC<{ children: React.ReactNode }> = React.memo(
  ({ children }) => {
    const {
      i18n: { resolvedLanguage },
    } = useTranslation();

    const [fbUser, setFbUser] = useState<User | null>(
      () => firebaseAuth.currentUser
    );
    const [ownUser, setOwnUser] = useState<IOwnUser | null>(null);
    const [isOwnLoading, setIsOwnLoading] = useState(false);
    const [isInited, setIsInited] = useState(false);
    const fbUserRef = useRef(fbUser);

    const silentFetchUser = useCallback(async () => {
      if (fbUserRef.current) {
        return getUserInfo({
          userId: fbUserRef.current.uid,
          userName: fbUserRef.current.displayName,
          photoURL: fbUserRef.current.photoURL,
        })
          .then((user) => {
            setOwnUser(user);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        throw new Error("There is no firebase user. Cannot fetch own user.");
      }
    }, []);

    const updateOwnUserData = useCallback((updates: Partial<IOwnUser>) => {
      setOwnUser((current) => {
        if (current === null) {
          return null;
        }
        return { ...current, ...updates };
      });
    }, []);

    useEffect(() => {
      // return unsubscribe func
      return firebaseAuth.onAuthStateChanged((user) => {
        setIsInited(true);
        setFbUser(user);
      });
    }, []);

    useEffect(() => {
      fbUserRef.current = fbUser;

      if (fbUser) {
        setIsOwnLoading(true);
        setOwnUser(null);
        getUserInfo({
          userId: fbUser.uid,
          userName: fbUser.displayName,
          photoURL: fbUser.photoURL,
        })
          .then((user) => {
            if (
              user.lang &&
              resolvedLanguage &&
              user.lang !== resolvedLanguage
            ) {
              setLanguage(user.lang);
            }

            setOwnUser(user);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsOwnLoading(false);
          });
      } else {
        setOwnUser(null);
        setIsOwnLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fbUser]);

    return isInited ? (
      <UserContext.Provider
        value={{
          fbUser,
          ownUser: {
            data: ownUser,
            isLoading: isOwnLoading,
            silentFetchUser,
            update: updateOwnUserData,
          },
        }}
      >
        {children}
      </UserContext.Provider>
    ) : (
      <WrappedLoader />
    );
  }
);

UserProvider.displayName = "UserProvider";

interface IUserContextValue {
  fbUser: User | null;
  ownUser: {
    data: IOwnUser | null;
    isLoading: boolean;
    silentFetchUser: () => Promise<void>;
    update: (data: Partial<IOwnUser>) => void;
  };
}

export const UserContext = createContext<IUserContextValue>({
  fbUser: null,
  ownUser: {
    data: null,
    silentFetchUser: () => Promise.reject(),
    update: () => void 0,
    isLoading: false,
  },
});
