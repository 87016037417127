import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./Image.module.scss";

interface IPropTypes {
  src: string;
  alt: string;
  className?: string;
  backgroundColor?: string;
  lazy?: boolean;
}

const Image: React.FC<IPropTypes> = ({
  src,
  alt,
  className,
  backgroundColor = "#0f1741",
  lazy,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div
      className={cn({
        [styles.Image]: true,
        [className!]: Boolean(className),
      })}
      data-role="image"
      style={{ backgroundColor }}
    >
      <img
        src={src}
        alt={alt}
        style={{ opacity: Number(isLoaded) }}
        onLoad={onLoad}
        loading={lazy ? "lazy" : "eager"}
      />
      {!isLoaded && <div className={styles.Image__AnimatedStub} />}
    </div>
  );
};

Image.displayName = "Image";

export default React.memo(Image);
