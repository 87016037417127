import { Navigate, RouteObject, matchRoutes } from "react-router-dom";
import { MainScreen } from "./screens/MainScreen/MainScreen";
import { MyQuizzesListScreen } from "./screens/MyQuizzesListScreen/MyQuizzesListScreen";
import { QuizScreen } from "./screens/QuizScreen/QuizScreen";
import { ProfileScreen } from "./screens/ProfileScreen/ProfileScreen";
import ProfileSettingsScreen from "./screens/ProfileScreen/ProfileSettingsScreen";
import { GlobalLeaderBoardScreen } from "./screens/LeaderBoardScreen/LeaderBoardScreen";
import QuestScreen from "./screens/QuestScreen/QuestScreen";
// import { RawPromptScreen } from './screens/RawPromptScreen/RawPromptScreen';

export const PATH = {
  root: "/",
  myList: "/my_list",
  quiz: "/quiz/:quizId",
  quizWithQIndex: "/quiz/:quizId/:questionIndex",
  questList: "/quest",
  quest: "/quest/movie/:movieId",
  profile: "/profile",
  profileSettings: "/profile_settings",
  leaders: "/leaders",
  any: "*",
};

export const routes: RouteObject[] = [
  {
    path: PATH.root,
    element: <MainScreen />,
  },
  {
    path: PATH.myList,
    element: <MyQuizzesListScreen />,
  },
  {
    path: PATH.questList,
    element: <QuestScreen />,
  },
  {
    path: PATH.quest,
    element: <QuestScreen />,
  },
  {
    path: PATH.quiz,
    element: <QuizScreen />,
  },
  {
    path: PATH.quizWithQIndex,
    element: <QuizScreen />,
  },
  // {
  //   path: PATH.rawPrompt,
  //   element: <RawPromptScreen />,
  // },
  {
    path: PATH.leaders,
    element: <GlobalLeaderBoardScreen />,
  },
  {
    path: PATH.profile,
    element: <ProfileScreen />,
  },
  {
    path: PATH.profileSettings,
    element: <ProfileSettingsScreen />,
  },
  {
    path: PATH.any,
    element: <Navigate to="/" replace={true} />,
  },
];

export function getRouteMatchPath(
  routes: RouteObject[],
  location: Partial<Location> | string
) {
  const matches = matchRoutes(routes, location);
  const getPath = (route: RouteObject) => {
    let path = route.path!;
    if (route.children?.length) {
      path += getPath(route.children[0]);
    }
    return path;
  };
  if (matches?.length) {
    return getPath(matches[0].route);
  }
  return null;
}
