import cn from "classnames";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IOnCompleteData } from "../../screens/QuizScreen/QuizScreen";
import {
  IAnswer,
  IQuiz,
  IQuizUserResult,
  QuizMode,
  QuizModeColors,
} from "../../types";
import { Avatar } from "../_base/Avatar/Avatar";
import { IQuestionHandler, Question } from "./Question";
import { Timer } from "./Timer";
import { useTimer } from "./useTimer";
import { vibrate } from "./vibrate";
import styles from "./Quiz.module.scss";

interface IPropTypes {
  quiz: IQuiz;
  mode: QuizMode;
  friend: IQuizUserResult | null;
  myPhotoURL: string;
  timerDuration?: number;
  onComplete: (data: IOnCompleteData) => void;
}

const CORRECT_TIMEOUT = 1000;
const INCORRECT_TIMEOUT = 3000;

export const Quiz: React.FC<IPropTypes> = React.memo(
  ({ quiz, mode, friend, myPhotoURL, timerDuration = 10, onComplete }) => {
    const answersRef = useRef<Array<number | null>>([]); // here must be exactly IAnswer['id'], because answers in question are shuffled after quiz fetch
    const answerTimesRef = useRef<number[]>([]);
    const answerPointsRef = useRef<number[]>([]);
    const correctCountRef = useRef(0);
    const scoresRef = useRef(0);
    const questionRef = useRef<IQuestionHandler | null>(null);
    const rootRef = useRef<HTMLDivElement | null>(null);

    const [questionIndex, setQuestionIndex] = useState(0);
    const [isShowExplanation, setIsShowExplanation] = useState(false);

    const [myScoresSum, friendScoresSum] = useMemo<[number, number]>(() => {
      let mySum = 0;
      let friendSum = 0;
      for (let i = 0; i < questionIndex; ++i) {
        mySum += answerPointsRef.current[i];
        if (friend) {
          friendSum += friend.answerPoints[i];
        }
      }
      return [mySum, friendSum];
    }, [questionIndex, friend]);

    const onNextQuestion = useCallback(() => {
      if (questionIndex < quiz.length - 1) {
        setQuestionIndex(questionIndex + 1);
      } else {
        onComplete({
          correctCount: correctCountRef.current,
          answerPoints: answerPointsRef.current,
          answers: answersRef.current,
          answerTimes: answerTimesRef.current,
          scores: scoresRef.current,
        });
      }
    }, [questionIndex, quiz.length, onComplete]);

    const onTimer = useCallback(() => {
      answersRef.current[questionIndex] = null;
      answerPointsRef.current[questionIndex] = 0;
      answerTimesRef.current[questionIndex] = 10;
      questionRef.current?.highlightCorrect();

      setTimeout(onNextQuestion, INCORRECT_TIMEOUT);
    }, [questionIndex, onNextQuestion]);

    const { timerState, startTimer, stopTimer } = useTimer(
      mode === QuizMode.competition ? timerDuration : null,
      onTimer
    );

    const onAnswer = useCallback(
      (qIndex: number, aIndex: number, answer: IAnswer) => {
        stopTimer();

        answersRef.current[qIndex] = answer.id;
        answerTimesRef.current[qIndex] = timerState.passedTime;

        if (friend) {
          questionRef.current?.setMyAnswer(aIndex, myPhotoURL);
          if (
            friend.answers[qIndex] !== undefined &&
            friend.answers[qIndex] !== null
          ) {
            questionRef.current?.setFriendAnswer(
              friend.answers[qIndex]!,
              friend.photoURL
            );
          }
        }

        let delayedFunction: Function = onNextQuestion;

        if (answer.is_correct) {
          vibrate.correct();

          // TODO: maybe need to save on different user's fields
          // scores from different quiz modes
          const questionScores =
            mode === QuizMode.competition
              ? getScoresByTime(timerState.passedTime)
              : 3;
          scoresRef.current += questionScores;
          ++correctCountRef.current;
          answerPointsRef.current[qIndex] = questionScores;
        } else {
          vibrate.incorrect();
          answerPointsRef.current[qIndex] = 0;
          questionRef.current?.highlightCorrect();

          if (mode === QuizMode.knowledge) {
            delayedFunction = () => {
              setIsShowExplanation(true);
            };
          }
        }

        window.setTimeout(
          delayedFunction,
          answer.is_correct ? CORRECT_TIMEOUT : INCORRECT_TIMEOUT
        );
      },
      [
        onNextQuestion,
        stopTimer,
        timerState.passedTime,
        friend,
        myPhotoURL,
        mode,
      ]
    );

    const onQuestionNextClick = useCallback(() => {
      setIsShowExplanation(false);
      onNextQuestion();
    }, [onNextQuestion]);

    useLayoutEffect(() => {
      startTimer();

      return () => {
        stopTimer();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, questionIndex]);

    useLayoutEffect(() => {
      if (rootRef.current && rootRef.current.parentElement) {
        const { offsetHeight: currentHeight, offsetTop: currentTop } =
          rootRef.current;
        const { offsetHeight: parentHeight } = rootRef.current.parentElement;
        const parentBottomPadding = parseFloat(
          window.getComputedStyle(rootRef.current.parentElement).paddingBottom
        );

        const extraHeight =
          currentTop + currentHeight - (parentHeight - parentBottomPadding);

        if (extraHeight > 0) {
          const text = rootRef.current.querySelector(
            '[data-role="question-text"]'
          );
          if (text instanceof HTMLElement) {
            const { offsetHeight: textHeight } = text;
            const targetHeight = textHeight - extraHeight;
            const currentFontSize = parseFloat(
              window.getComputedStyle(text).fontSize
            );
            const newFontSize = Math.max(
              16,
              (currentFontSize * targetHeight) / textHeight
            );

            text.style.fontSize = `${newFontSize}px`;
            text.style.lineHeight = `${newFontSize + 2}px`;
          }
        }
      }
    }, [questionIndex]);

    return (
      <>
        <div className={styles.Quiz__Overlay} />
        <div className={styles.Quiz} ref={rootRef}>
          <div className={styles.Quiz__TopContainer}>
            <Timer
              key={
                mode === QuizMode.competition
                  ? `timer${questionIndex}`
                  : undefined
              }
              timerState={timerState}
              duration={timerDuration}
              questionIndex={questionIndex}
              questionsLength={quiz.length}
              mode={mode}
            />
            <QuizScores
              friendPhotoURL={friend?.photoURL}
              myPhotoURL={myPhotoURL}
              myScores={myScoresSum}
              friendScores={friendScoresSum}
            />
          </div>
          <Question
            key={questionIndex}
            ref={questionRef}
            question={quiz[questionIndex]}
            index={questionIndex}
            modeColor={QuizModeColors[mode]}
            isShowExplanation={isShowExplanation}
            onAnswer={onAnswer}
            onNextClick={onQuestionNextClick}
          />
        </div>
      </>
    );
  }
);

Quiz.displayName = "Quiz";

const QuizScores: React.FC<{
  friendPhotoURL: string | undefined;
  myPhotoURL: string;
  friendScores: number;
  myScores: number;
}> = React.memo(({ friendPhotoURL, myPhotoURL, friendScores, myScores }) => {
  return (
    <div className={styles.CompetitonScores}>
      <div
        className={cn(
          styles.CompetitonScores__Container,
          styles.CompetitonScores__Container_My
        )}
      >
        <Avatar size={40} photoURL={myPhotoURL} />
        <div className={styles.CompetitonScores__Counter}>{myScores}</div>
      </div>
      {friendPhotoURL && (
        <div
          className={cn(
            styles.CompetitonScores__Container,
            styles.CompetitonScores__Container_Friend
          )}
        >
          <Avatar size={40} photoURL={friendPhotoURL} />
          <div className={styles.CompetitonScores__Counter}>{friendScores}</div>
        </div>
      )}
    </div>
  );
});

function getScoresByTime(passedTime: number): number {
  let result: number;
  if (passedTime <= 2) {
    result = 4;
  } else if (passedTime <= 4) {
    result = 3;
  } else if (passedTime <= 6) {
    result = 2;
  } else if (passedTime <= 8) {
    result = 1;
  } else {
    result = 0;
  }
  return result;
}
