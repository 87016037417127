import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../_base/Button/Button";
import { Input } from "../_base/Input/Input";
import { Text } from "../_base/Text/Text";
import styles from "./QuizPrompt.module.scss";

interface IPropTypes {
  prompt: string;
  onPromptChange: (value: string) => void;
  onClick: () => void;
}

export const QuizPrompt: React.FC<IPropTypes> = React.memo(
  ({ prompt, onPromptChange, onClick }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.QuizPrompt}>
        <div className={styles.QuizPrompt__TextWrapper}>
          <Text
            fontSize={28}
            letterSpacing="0.56rem"
            fontWeight={700}
            color="white"
            textAlign="left"
            fontFamily="Yanone Kaffeesatz"
          >
            {t("enterTopic")}
          </Text>
          <Text fontSize={14} fontWeight={400} color="#ccc" textAlign="left">
            {t("promptLabel")}
          </Text>
        </div>
        <div className={styles.QuizPrompt__InputWrapper}>
          <Input
            value={prompt}
            placeholder={t("writeSomething")}
            onChange={onPromptChange}
          />
          {/* // TODO: change bgColor by app mode (quiz, quest, test)  */}
          <Button label="GO" bgColor="#b6f36a" onClick={onClick} />
        </div>
      </div>
    );
  }
);

QuizPrompt.displayName = "QuizPrompt";
