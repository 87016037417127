export const SessionStorageKeys = {
  MAIN_SCROLL: 'mainScroll',
  MY_LIST_SCROLL: 'myListScroll',
};

export const Storage = {
  setItem: (engine: Storage, key: string, value: string) => {
    try {
      engine.setItem(key, value);
    } catch (error) {
      console.error(error);
    }
  },

  getItem: (engine: Storage, key: string): string | null => {
    let result: string | null;
    try {
      result = engine.getItem(key);
    } catch (error) {
      console.error(error);
      result = null;
    }
    return result;
  },
  removeItem: (engine: Storage, key: string) => {
    try {
      engine.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  },
};
